<template lang="pug">
Container
	UnitReviewsGroup

Container
	UnitCases

Container
	UnitReviews

Container
	UnitConsultation(type='employees')

Container
	UnitVideoReviews

Container

UiSubscribe
</template>

<script lang="ts" setup>
defineComponent({
	name: 'ReviewsPage',
});

const { header } = useMeta();
</script>
